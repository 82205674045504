<template>
   <div>
    <v-banner single-line class="banner">
      <v-img
        src="https://h5.ophyer.cn/official_website/banner/Numberc-banner-h5.png"
        height="100%"
        :transition="false"
        class="d-flex align-center"

      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
                indeterminate
                size="80"
                color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="d-flex  max-width-1200">
          <v-card color="transparent" flat >
            <div class="d-flex flex-no-wrap justify-space-between ml-4">
              <div>
                <v-card-title class="white--text h5-banner-title font-size-h5banner-title" >
                  数字资产
                </v-card-title>
              </div>
            </div>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>

    <div class="con con1" style="background: #fff;">
      <v-card flat color="transparent">
        <div class="ftyd-public-title-h5">
          <div>
            <span>数字确权</span>
            <span>aPaaS engine</span>
          </div>
        </div>
        <v-card class="wrap" flat style="background: #fff;">
          <div class="box-img">
            <v-img src="https://h5.ophyer.cn/official_website/other/Numberc-img01.png" width="100%" min-height="200" contain>
              <template v-slot:placeholder><img-placeholder></img-placeholder></template>
            </v-img>
          </div>
          <div class="text-wrap">
            <v-card class="pa-0" flat style="background: #fff;">
              <v-card-title  class="con_tit pa-0 mb-5">提供可信的存证服务</v-card-title>
              <v-card-text  class="con_text pa-0">将身份、信息、资产、行为上链，无法修改</v-card-text>
              <v-card-text class="text">• 数据采用分布式账本保存，并由各有公信</v-card-text>
              <v-card-text class="text">• 高数据安全、高性能低延时以及集群灾备高等特点<br>保证了数据的可靠流转</v-card-text>
              <v-card-text class="text">• 业界领先的电子合同、金融、版权、租赁等应用场景和商业实践</v-card-text>
            </v-card>
          </div>
        </v-card>
      </v-card>
    </div>

    <div class="con con2" style="background: #FBFBFB;">
      <v-card flat color="transparent">
        <div class="ftyd-public-title-h5">
          <div>
            <span>解决方案</span>
            <span>aPaaS engine</span>
          </div>
        </div>
        <v-card class="wrap" flat style="background: #FBFBFB;">
          <div class="box-img">
            <v-img src="https://h5.ophyer.cn/official_website/other/Numberc-img02.png" width="100%" min-height="200" contain>
              <template v-slot:placeholder><img-placeholder></img-placeholder></template>
            </v-img>
          </div>
          <div class="text-wrap">
            <v-card class="pa-0" flat style="background: #FBFBFB;">
              <v-card-title  class="con_tit pa-0 mb-5">海量3D模型授权</v-card-title>
            </v-card>
          </div>
        </v-card>
      </v-card>
    </div>

    <div class="con con2" style="background: #fff;padding-top: 43px;">
      <v-card flat color="transparent">
        <v-card class="wrap" flat style="background: #fff;">
          <div class="box-img">
            <v-img src="https://h5.ophyer.cn/official_website/other/Numberc-img03.png" width="100%" min-height="200" contain>
              <template v-slot:placeholder><img-placeholder></img-placeholder></template>
            </v-img>
          </div>
          <div class="text-wrap">
            <v-card class="pa-0" flat style="background: #fff;">
              <v-card-title class="con_tit pa-0 mb-5" >数字资产交易</v-card-title>
            </v-card>
          </div>
        </v-card>
      </v-card>
    </div>
    <div class="con con2" style="background: #FBFBFB;padding-top: 43px;">
      <v-card flat color="transparent">
        <v-card class="wrap" flat style="background: #FBFBFB;">
          <div class="box-img">
            <v-img src="https://h5.ophyer.cn/official_website/other/Numberc-img04.png" width="100%" min-height="200" contain>
              <template v-slot:placeholder><img-placeholder></img-placeholder></template>
            </v-img>
          </div>
          <div class="text-wrap">
            <v-card class="pa-0" flat style="background: #FBFBFB;">
              <v-card-title  class="con_tit pa-0 mb-5">数字资产价格标准</v-card-title>
            </v-card>
          </div>
        </v-card>
      </v-card>
    </div>

    <div class="con con2" style="background: #fff;padding-top: 43px;">
      <v-card flat color="transparent">
        <v-card class="wrap" flat style="background: #fff;">
          <div class="box-img">
            <v-img src="https://h5.ophyer.cn/official_website/other/Numberc-img05.png" width="100%" min-height="200" contain>
              <template v-slot:placeholder><img-placeholder></img-placeholder></template>
            </v-img>
          </div>
          <div class="text-wrap">
            <v-card class="pa-0" flat style="background: #fff;">
              <v-card-title class="con_tit pa-0 mb-5" >数字资产指数标准</v-card-title>
            </v-card>
          </div>
        </v-card>
      </v-card>
    </div>

    <div class="con con2" style="background: #FBFBFB;padding-top: 43px;">
      <v-card flat color="transparent">
        <v-card class="wrap" flat style="background: #FBFBFB;">
          <div class="box-img">
            <v-img src="https://h5.ophyer.cn/official_website/other/Numberc-img06.png" width="100%" min-height="200" contain>
              <template v-slot:placeholder><img-placeholder></img-placeholder></template>
            </v-img>
          </div>
          <div class="text-wrap">
            <v-card class="pa-0" flat style="background: #FBFBFB;">
              <v-card-title  class="con_tit pa-0 mb-5">区块链身份验证标准</v-card-title>
            </v-card>
          </div>
        </v-card>
      </v-card>
    </div>

    <div class="con con2" style="background: #fff;padding-top: 43px;">
      <v-card flat color="transparent">
        <v-card class="wrap " flat style="background: #fff;">
          <div class="box-img">
            <v-img src="https://h5.ophyer.cn/official_website/other/Numberc-img07.png" width="100%" min-height="200" contain>
              <template v-slot:placeholder><img-placeholder></img-placeholder></template>
            </v-img>
          </div>
          <div class="text-wrap">
            <v-card class="pa-0" flat style="background: #fff;">
              <v-card-title class="con_tit pa-0 mb-5" >区块链防伪溯源标准</v-card-title>
            </v-card>
          </div>
        </v-card>
      </v-card>
    </div>

    <div class="con con2" style="background: #FBFBFB;padding-top: 43px;">
      <v-card flat color="transparent">
        <v-card class="wrap" flat style="background: #FBFBFB;">
          <div class="box-img">
            <v-img src="https://h5.ophyer.cn/official_website/other/Numberc-img08.png" width="100%" min-height="200" contain>
              <template v-slot:placeholder><img-placeholder></img-placeholder></template>
            </v-img>
          </div>
          <div class="text-wrap">
            <v-card class="pa-0" flat style="background: #FBFBFB;">
              <v-card-title  class="con_tit pa-0 mb-5">图计算反洗钱标准</v-card-title>
            </v-card>
          </div>
        </v-card>
      </v-card>
    </div>

    <div class="con con3" style="background: #fff;">
      <v-card flat color="transparent">
        <div class="ftyd-public-title-h5">
          <div>
            <span>应用领域</span>
            <span>aPaaS engine</span>
          </div>
        </div>
        <ul>
          <li>
            <div class="box-img">
              <v-img src="https://h5.ophyer.cn/official_website/other/Numberc-img09.png" width="100%"  contain>
                <template v-slot:placeholder><img-placeholder></img-placeholder></template>
              </v-img>
            </div>
            <p>固定资产上链</p>
          </li>
          <li>
            <div class="box-img">
              <v-img src="https://h5.ophyer.cn/official_website/other/Numberc-img10.png" width="100%" contain>
                <template v-slot:placeholder><img-placeholder></img-placeholder></template>
              </v-img>
            </div>
            <p>区块链产品溯源</p>
          </li>
          <li>
            <div class="box-img">
              <v-img src="https://h5.ophyer.cn/official_website/other/Numberc-img11.png" width="100%" contain>
                <template v-slot:placeholder><img-placeholder></img-placeholder></template>
              </v-img>
            </div>
            <p>通证资产管理</p>
          </li>
          <li>
            <div class="box-img">
              <v-img src="https://h5.ophyer.cn/official_website/other/Numberc-img12.png" width="100%" contain>
                <template v-slot:placeholder><img-placeholder></img-placeholder></template>
              </v-img>
            </div>
            <p>区块链股权</p>
          </li>
        </ul>
      </v-card>
    </div>

    <div class="con con2" style="background: #FBFBFB;">
      <v-card flat color="transparent">
        <div class="ftyd-public-title-h5">
          <div>
            <span>精选案例</span>
            <span>3D Content productio</span>
          </div>
        </div>
        <v-card class="wrap" flat style="background: #FBFBFB;">
          <div class="box-img">
            <v-img src="https://h5.ophyer.cn/official_website/other/Numberc-img13.png" width="100%" min-height="200" contain>
              <template v-slot:placeholder><img-placeholder></img-placeholder></template>
            </v-img>
          </div>
          <div class="text-wrap" style="margin-bottom: 20px;">
            <v-card class="pa-0" flat style="background: #FBFBFB;">
              <v-card-title  class="con_tit pa-0 mb-5">区块链登记溯源系统</v-card-title>
            </v-card>
          </div>
        </v-card>
        <v-card class="wrap" flat style="background: #FBFBFB;">
          <div class="box-img">
            <v-img src="https://h5.ophyer.cn/official_website/other/Numberc-img14.png" width="100%" min-height="200" contain>
              <template v-slot:placeholder><img-placeholder></img-placeholder></template>
            </v-img>
          </div>
          <div class="text-wrap" style="margin-bottom: 20px;">
            <v-card class="pa-0" flat style="background: #FBFBFB;">
              <v-card-title  class="con_tit pa-0 mb-5">KYC服务</v-card-title>
            </v-card>
          </div>
        </v-card>
        <v-card class="wrap" flat style="background: #FBFBFB;">
          <div class="box-img">
            <v-img src="https://h5.ophyer.cn/official_website/other/Numberc-img15.png" width="100%" min-height="200" contain>
              <template v-slot:placeholder><img-placeholder></img-placeholder></template>
            </v-img>
          </div>
          <div class="text-wrap" style="margin-bottom: 20px;">
            <v-card class="pa-0" flat style="background: #FBFBFB;">
              <v-card-title  class="con_tit pa-0 mb-5">数字货币审计标准</v-card-title>
            </v-card>
          </div>
        </v-card>
      </v-card>
    </div>
  
   </div>
</template>

<script>
export default {
  name: "OnlineRetailers",
  data(){
    return{
      model: 0,
      bannerLoad: false,
      imgLoad: false,
    }
  },
  mounted(){
    this.bannerLoad = true;
  },
  methods: {
    handleLoad (){
      this.imgLoad = true;
    },
  
  }
}
</script>

<style scoped lang='scss'>

.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
}
.banner_title{
  font-size: 60px;
  color: #FFFFFF !important;
}
::v-deep .v-banner__wrapper{
  padding: 0 !important;
}

::v-deep .v-carousel__controls{
  display: none !important;
}

.con {
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
  padding: 0 20px;
  .wrap {
    margin-top: -5px !important;
  }
}
.con1 {
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
  background: #FBFBFB;
  .v-card {
    background: #FBFBFB;
  }
  .box-img {
    width: 100%;
    border-radius: 10px;
    img {
      width: 100%;
      height: 270px;
      border-radius: 10px;
    }
    ::v-deep .v-image .v-image__image {
      border-radius: 10px !important;
    }
    ::v-deep v-responsive__content {
      border-radius: 10px !important;
    }
    ::v-deep .v-image .v-responsive__content {
      border-radius: 10px !important;
    }
  }
  .text-wrap{
    margin-top: -50px;
    .con_tit{
      font-size: 18px;
      // font-weight: bold;
      color: #333333;
      line-height: 30px;
      margin-top: 54px;
      position: relative;
      &::before {
        content: '';
        width: 60px;
        height: 4px;
        background: #000000;
        position: absolute;
        bottom: -10px;
        left: 0;
      }
    }
    .con_text{
      height: 40px;
      font-size: 14px;
      font-weight: 400;
      color: #666;
      line-height: 20px;
    }
    .text {
      line-height: 17px;
      padding: 0;
      margin: 0;
      padding-left: 0;
    }
  }
}

.con2 {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  background: #FBFBFB;
  .v-card {
    background: #FBFBFB;
  }
  .box-img {
    width: 100%;
    border-radius: 10px;
    img {
      width: 100%;
      height: 270px;
      border-radius: 10px;
    }
    ::v-deep .v-image__image {
      border-radius: 10px;
    }
  }
  .text-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    .con_tit{
      // margin-top: 15px;
      font-size: 18px;
      // font-weight: bold;
      color: #333333;
      line-height: 30px;
      position: relative;
    }
  }
}

.con3 {
  ul {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
  }
  li {
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    .box-img {
      width: 96%;
      border-radius: 10px;
      img {
        width: 100%;
        border-radius: 10px;
        // height: 270px;
      }
      ::v-deep .v-image__image {
        border-radius: 10px;
      }
    }
    // img {
    //   width: 260px;
    //   height: 146px;
    //   border-radius: 10px;
    // }
    p {
      margin-top: 8px;
      font-size: 18px;
      // font-weight: bold;
      color: #333333;
    }
  }
}
</style>
